@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bauhaus93';
  src: url('./assets/fonts/Bauhaus-93.ttf') format('truetype');
}

@font-face {
  font-family: 'Bauhaus';
  src: url('./assets/fonts/Bauhaus-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaPro-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./assets/fonts/Gotham-Bold.otf') format('truetype');
}

* {
  position: relative;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.orange-btn {
  background-image: linear-gradient(90deg, #ff5100 0%, #ff8f01 100%);
  border: none;
  border-radius: 10px;
  width: 100%;
  min-height: 42px;
  color: white;
  font-weight: bold;
}

.orange-text {
  color: rgb(252, 60, 8);
}

.layout-bg {
  background-color: rgb(244, 244, 244);
}

.orange-bg {
  /* background: linear-gradient(90deg, #ff5100 0%, #ff8f01 100%); */
  background-image: linear-gradient(90deg, #ff5100 0%, #ff8f01 100%);
}

.font-sofia-pro {
  font-family: 'SofiaPro';
}

.font-montserrat {
  font-family: 'Montserrat';
}

.font-bauhaus {
  font-family: 'Bauhaus';
}

.font-bauhaus93 {
  font-family: 'Bauhaus93';
}

.font-poppins-extrabold {
  font-family: 'Poppins-ExtraBold';
}

.font-gotham-bold {
  font-family: 'Gotham-Bold';
}

.inactive-link .link-text {
  color: rgb(127, 127, 127);
}

.inactive-link .nav-icon {
  stroke: #7f7f80;
}

.active-link .link-text {
  background: linear-gradient(90deg, #ff5100 0%, #ff8f01 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.custom-max-height {
  max-height: calc(100vh - 180px - 80px);
}
